// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "B_vl d_gS d_cw d_dv";
export var quoteParagraphCenter = "B_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "B_gV d_gV d_cw d_dx";
export var quoteRowLeft = "B_vm d_bG";
export var quoteRowCenter = "B_vn d_bD";
export var quoteRowRight = "B_vp d_bH";
export var quoteWrapper = "B_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "B_gR d_gR";
export var quoteExceptionSmall = "B_t2 z_t2";
export var quoteExceptionNormal = "B_t3 z_t3";
export var quoteExceptionLarge = "B_t4 z_t4";
export var quoteAuthorExceptionSmall = "B_t5 z_t5";
export var quoteAuthorExceptionNormal = "B_t6 z_t6";
export var quoteAuthorExceptionLarge = "B_t7 z_t7";