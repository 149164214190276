// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "w_sx d_gv d_cs";
export var heroHeaderCenter = "w_gw d_gw d_cs d_dw";
export var heroHeaderRight = "w_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "w_sy d_gr d_cw";
export var heroParagraphCenter = "w_gs d_gs d_cw d_dw";
export var heroParagraphRight = "w_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "w_sz d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "w_sB d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "w_sC d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "w_sD d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "w_sF d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "w_sG z_sG";
export var heroExceptionNormal = "w_sH z_sH";
export var heroExceptionLarge = "w_sJ z_sJ";
export var Title1Small = "w_sK z_sK z_tk z_tl";
export var Title1Normal = "w_sL z_sL z_tk z_tm";
export var Title1Large = "w_sM z_sM z_tk z_tn";
export var BodySmall = "w_sN z_sN z_tk z_tD";
export var BodyNormal = "w_sP z_sP z_tk z_tF";
export var BodyLarge = "w_sQ z_sQ z_tk z_tG";