// extracted by mini-css-extract-plugin
export var carouselContainer = "J_v8 d_w d_H d_bf d_Z";
export var carouselContainerCards = "J_v9 J_v8 d_w d_H d_bf d_Z";
export var carouselContainerSides = "J_wb d_w d_H d_Z";
export var prevCarouselItem = "J_wc d_w d_H d_0 d_k";
export var prevCarouselItemL = "J_wd J_wc d_w d_H d_0 d_k";
export var moveInFromLeft = "J_wf";
export var prevCarouselItemR = "J_wg J_wc d_w d_H d_0 d_k";
export var moveInFromRight = "J_wh";
export var selectedCarouselItem = "J_wj d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "J_wk J_wj d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "J_wl J_wj d_w d_H d_Z d_bf";
export var nextCarouselItem = "J_wm d_w d_H d_0 d_k";
export var nextCarouselItemL = "J_wn J_wm d_w d_H d_0 d_k";
export var nextCarouselItemR = "J_wp J_wm d_w d_H d_0 d_k";
export var arrowContainer = "J_wq d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "J_wr J_wq d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "J_ws J_wr J_wq d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "J_wt J_wq d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "J_wv";
export var nextArrowContainerHidden = "J_ww J_wt J_wq d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "J_kG d_0";
export var prevArrow = "J_wx J_kG d_0";
export var nextArrow = "J_wy J_kG d_0";
export var carouselIndicatorContainer = "J_wz d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "J_wB d_w d_bz d_bF";
export var carouselText = "J_wC d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "J_wD J_wC d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "J_wF d_b7";
export var carouselIndicator = "J_wG J_wF d_b7";
export var carouselIndicatorSelected = "J_wH J_wF d_b7";
export var arrowsContainerTopRight = "J_wJ d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "J_wK d_0 d_bl d_bC";
export var arrowsContainerSides = "J_wL d_0 d_bl d_bC";
export var smallArrowsBase = "J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "J_wN J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "J_wP J_wN J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "J_wQ J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "J_wR J_wQ J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "J_n9";
export var iconSmall = "J_wS";
export var multipleWrapper = "J_wT d_bC d_bF d_bf";
export var multipleImage = "J_wV d_bC";
export var sidesPrevContainer = "J_wW J_wN J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "J_wX J_wN J_wM d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";